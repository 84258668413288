.texto-link {
  color: rgb(58, 80, 223);
}
.cta-bottom {
  margin-top: 80px;
  margin-bottom: 50px;
}
@media(max-width: 767px) {
  .pago-en-linea {
    margin-top: 30px;
  }
}
.site-header {
  padding: 10px 20px; /* Adjust to your site's needs */
  background: #fff; /* Or any color that matches your design */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: adds depth */
}

.logo-link {
  display: inline-block;
  text-decoration: none; /* Removes underline from the link */
}

.site-logo {
  height: 100px; /* Adjust based on your logo's design */
  width: auto; /* Keeps the logo's aspect ratio */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .site-logo {
    height: 40px; /* Slightly smaller logo for mobile devices */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;
  }
}

.pay-button {
  background-color: #007bff; /* Blue background */
  color: white; /* White text */
  border: none;
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.credit-card-icon {
  width: 24px;
  height: 24px;
}

.pay-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-header {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.home-burger-menu {
  display: none;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.home-mobile-menu1 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.home-links {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.home-nav12 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav22 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav32 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav42 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav52 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-buttons {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 96px;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url('/external/foto-saltando.jpeg'); /* Cambia esto por la ruta de tu imagen */
  background-size: cover; /* Esto hará que la imagen cubra completamente el contenedor */
  background-position: center;
}
.home-hero1 {
  height: 484px;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
}
.home-container01 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-hero-heading {
  font-size: 48px;
  max-width: 800px;
  text-align: center;
  font-family: "Raleway";
  font-weight: 700;
  line-height: 150%;
}
.home-hero-sub-heading {
  font-size: 18px;
  text-align: center;
}
.home-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-features {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-features1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
  background-color: #7c274e;
}
.home-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  justify-content: center;
}
.home-text14 {
  text-align: center;
  line-height: 150%;
}
.home-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: var(--dl-space-space-sixunits);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.home-video {
  width: 320px;
  height: 180px;
}
.home-pricing {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-pricing1 {
  height: 1180px;
}
.home-container04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-pricing-heading {
  height: 89px;
  text-align: center;
}
.home-pricing-sub-heading {
  text-align: center;
}
.home-container05 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-container06 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text19 {
  text-transform: uppercase;
}
.home-container07 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-text20 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.home-basic-plan-pricing {
  font-size: 4rem;
  font-weight: 700;
}
.home-text23 {
  font-size: 1.15rem;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.home-container08 {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
@media (max-width: 767px) {
  .home-container08 {
    height: 142px;
  }
}
.home-container09 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text24 {
  line-height: 150%;
}
.home-container10 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text26 {
  line-height: 150%;
}
.home-container11 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text27 {
  line-height: 150%;
}
.home-container12 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text28 {
  line-height: 150%;
}
.home-image {
  width: 180px;
  height: 105px;
  object-fit: cover;
}
.home-image1 {
  width: 193px;
  height: 120px;
  object-fit: cover;
}
.home-button {
  width: 100%;
}
.home-container13 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #7c274e;
}
.home-text46 {
  color: white;
  height: 129px;
  margin-top: 90px;
}
@media (max-width: 767px) {
  .home-text46 {
    text-align: center;
    margin-top: 60px;
  }
}
.home-gallery {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.home-text47 {
  color: var(--dl-color-gray-700);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  padding-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-threeunits);
}
.home-container14 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.home-banner {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-banner1 {
  height: 300px;
  background-color: #f3dcbe;
}
.home-banner-sub-heading {
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-banner-heading {
  height: 78px;
  text-align: center;
}
.home-banner-button {
  width: 158px;
  height: 59px;
  text-decoration: none;
}
.home-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-container15 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-nav1 {
  flex: 0 0 auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.home-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-container16 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-link {
  display: contents;
}
.home-icon10 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
@media(max-width: 991px) {
  .home-hero1 {
    flex-direction: column;
  }
  .home-container01 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-hero-heading {
    text-align: center;
  }
  .home-hero-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-pricing-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-container05 {
    align-items: center;
    flex-direction: column;
  }
  .home-pricing-card {
    width: 100%;
    max-width: 450px;
  }
  .home-text47 {
    text-align: center;
  }
  .home-container14 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .home-banner-sub-heading {
    max-width: 100%;
  }
}
@media(max-width: 767px) {
  .home-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-desktop-menu {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-nav12 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav22 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav32 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav42 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav52 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-hero-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-features1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    height: 100%;
  }
  .home-pricing-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-container05 {
    align-items: center;
    flex-direction: column;
  }
  .home-pricing-card {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-gallery {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text47 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-container14 {
    grid-template-columns: 1fr 1fr;
  }
  .home-banner1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-banner-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container16 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text68 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .home-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .home-mobile-menu1 {
    padding: 16px;
  }
  .home-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-btn-group {
    flex-direction: column;
  }
  .home-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-gallery {
    padding: var(--dl-space-space-unit);
  }
  .home-container14 {
    grid-template-columns: 1fr;
  }
  .home-banner1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container16 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text68 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
