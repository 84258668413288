.gallery-card3-gallery-card {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.gallery-card3-image {
  top: 0px;
  left: 0px;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.gallery-card3-container {
  width: 100%;
  height: 300px;
  display: flex;
  opacity: 0;
  padding: var(--dl-space-space-twounits);
  z-index: 1;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.9));;
}
/* .gallery-card3-container:hover {
  opacity: 1;
}
*/
.gallery-card3-text {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.gallery-card3-text1 {
  color: var(--dl-color-gray-white);
  text-align: center;
}












@media(max-width: 479px) {
  .gallery-card3-text {
    align-self: center;
    margin-bottom: var(--dl-space-space-unit);
  }
}
